import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import { useApps } from './useApps'

export const useApp = (appId?: MaybeRef<number>) => {
  const { data: apps } = useApps({ enabled: !!unref(appId) })

  return computed(() =>
    apps.value?.data?.applications.items?.find((app) => app.id === unref(appId))
  )
}
